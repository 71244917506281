.SliderContainer {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.SliderRow {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 100%;
    
    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.SliderRowItems {
    margin: 10px;
    background-color: #ffffff;
    border-radius: 100px;
}

.SliderRowFiller {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    margin: 10px;
}

.SliderBox {
    width: 1000px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.BottomTextBox {
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100px;
    
    display:flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.BoxFiller {
    width: 100%;
    height: 100%;
}

.BottomTextBox a {
    position: relative;
    text-align: center;
    font-size: 10pt;
    white-space: nowrap;
    font-family: 'Rajdhani', sans-serif;
    text-decoration: none;
    color: #1e1e1e;
    top: 0;
}

.BottomTextBox a:hover {
    color: #ff0000;
}

.slider {
    -webkit-appearance: none;
    width: 95%;
    height: 10px;
    background-color: #1e1e1e;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 5px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #ff0000;
    cursor: pointer;
    
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #ff0000;
    cursor: pointer;
    border-radius: 100px;
    outline-width: 0;
  }