:root {
    --base-tile-size: 100px;
    --viewport-width: 100vw;
    --variable-tile-size: 100px;
}

.BackroundContainer {
    

    background-color: #FF0000;
    position: absolute;
    z-index: -100;
    width: 101vw;
    height: 100vh;
    top: 0;
    left: 0;
    --container-width: var(width);
    --container-height: var(height);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

.Tile {
    width: var(--variable-tile-size);
    height: var(--variable-tile-size);
    z-index:-90;

}