.Credmenu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GiveMargin {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
}

.BelowPfp {
    margin-top: 30px;
    padding: 10px;
    padding-left:30px;
    padding-right:30px;    
    font-size: x-large;
    text-align: center;
    max-width: 80%;
    color: #1E1E1E;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 100px;
}

.BelowPfp a {
    text-decoration: none;
    color: currentColor;
}

.HomepageLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HomepageDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 175px;
    flex-shrink: 1;
    min-width: 300px;
    max-width: 500px;
}

.HomepageDataContainer p {
    position: relative;
    margin: 0;
    font-size: 12pt;
}

.HomepageDataContainer h3 {
    position: relative;
    margin: 0;
    top: -9px;
}